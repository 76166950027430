import React from 'react'
import { Controller, FieldError } from 'react-hook-form';

import { Select } from '@components'
import { OptionT } from '@types'
import { EMPLOYEE_MANAGER_ROLES_OPTIONS } from '@lib/constants'

type Props = {
  control: any
  index: number
  managerOptions: OptionT[]
  onRemove: (index: number) => void
  errors: {manager_id?: FieldError, role?: FieldError} | undefined
}

export const ManagerField = (({control, onRemove, index, managerOptions, errors } : Props) => {
  return (
    <div className="flex sm:flex-wrap md:flex-nowrap sm:flex-col md:flex-row gap-4 my-4 w-full items-start">
      <input type="hidden" value={index} {...control.register(`employee_managers.${index}.manager_order`)} />

      <Controller
        control={control}
        name={`employee_managers.${index}.manager_id`}
        render={({field}) => (
          <Select
            {...field}
            label="Manager"
            placeholder="Select Manager"
            options={managerOptions}
            onChange={(e) => field.onChange(e?.value)}
            required
            errorMessage={errors?.manager_id?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={`employee_managers.${index}.role`}
        render={({field}) => (
          <Select
            {...field}
            label="Role"
            placeholder="Select Role"
            options={EMPLOYEE_MANAGER_ROLES_OPTIONS}
            onChange={(e) => field.onChange(e?.value)}
            required
            errorMessage={errors?.role?.message}
          />
        )}
      />
      <button type="button" className="btn btn-xs sm:w-full md:max-w-9 min-h-9 md:mt-6 bg-action-critical-default border-none hover:bg-action-critical-hovered " onClick={() => onRemove(index)}>
        x
      </button>
    </div>
  )
});
