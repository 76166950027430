import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { capitalize } from 'lodash'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'

import { formatDateWithPeriod } from '@lib/helper'
import { BulkApproveTeamLeaveParam, TeamLeavePendingDataT } from '@types'

import { closeModal, ErrorText, Modal, Radio, TextAreaField } from '@components'

const schema = z
  .object({
    id: z.number(),
    status: z.string(),
    reason: z.string().optional(),
  })
  .refine(
    (input) => {
      if (input.status === 'approve') return true
      if (input.status === 'reject' && input.reason) return true
      return false
    },
    { message: 'reason is required', path: ['reason'] },
  )

const approveOptions = [
  {
    label: 'Approve',
    value: 'approve',
  },
  {
    label: 'Reject',
    value: 'reject',
  },
]

export const BULK_LEAVE_APPROVE_FORM = 'bulk-leave-approve-form'

type Props = {
  dataList: TeamLeavePendingDataT[]
  onSubmit: (data: BulkApproveTeamLeaveParam[]) => void
}

export const BulkApproveModal = ({ dataList, onSubmit }: Props) => {
  const boxData = useMemo(
    () =>
      dataList.map((data) => [
        {
          label: 'Employee Name',
          value: capitalize(data.employee_name),
        },
        {
          label: 'Leave Type',
          value: `${capitalize(
            data.leave_type_name.split('_').join(' '),
          )} Leave`,
        },
        {
          label: 'Leave Date',
          value: formatDateWithPeriod(data.datetime_range, data.period),
        },
        {
          label: 'Reason',
          value: data?.reason ?? '-',
        },
      ]),
    [dataList],
  )

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
  } = useForm<BulkApproveTeamLeaveParam>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: 1,
      reason: undefined,
    },
  })

  const watchFields = watch(['status'])

  const isShownReason = useMemo(() => {
    return watchFields[0] === 'reject'
  }, [watchFields])

  return (
    <Modal name={BULK_LEAVE_APPROVE_FORM}>
      <div className="grid text-center justify-center w-full">
        <div className="grid gap-5 mb-5">
          <img src="/assets/warning_icon.svg" className="m-auto" />
          <h2 className="font-bold">Approve Leaves</h2>
        </div>

        <div className="grid gap-4">
          <p className="text-text-subdued">
            Your employees has submitted leave requests as below.
          </p>

          <div
            id="boxList"
            className="card card-border max-h-40 overflow-y-auto"
          >
            {boxData.map((items, index) => (
              <div key={`bulk-${index}`}>
                {items.map((item, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-[1fr_2fr] text-left text-sm"
                  >
                    <span className="font-semibold">{item.label}:</span>
                    <span className="capitalize">{item.value}</span>
                  </div>
                ))}
                <div className="divider my-0" />
              </div>
            ))}
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid justify-start p-[24px_0px] w-full text-start">
            <p>
              Approval <span className="required-icon">*</span>
            </p>
            <div className="grid grid-cols-2 gap-2 w-[455px]">
              {approveOptions.map((item, index) => (
                <Radio
                  key={`approve-option-${index}`}
                  option={item}
                  onChange={(value) => {
                    setValue('status', value as string)
                  }}
                  value={getValues('status')}
                />
              ))}
            </div>
            {errors.status?.message && (
              <ErrorText text={errors.status.message} />
            )}
            {isShownReason && (
              <div className="mt-[24px]">
                <TextAreaField
                  placeholder="Enter reject reason"
                  label="Reject Reason"
                  labelSize="md"
                  name="reason"
                  register={register}
                  required
                  errorMessage={errors.reason?.message}
                />
              </div>
            )}
          </div>
          <div className="flex flex-row gap-4 justify-end pb-4">
            <button
              type="button"
              className="btn btn-primary-outline capitalize"
              onClick={() => closeModal([BULK_LEAVE_APPROVE_FORM])}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary-solid">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
