import React, { useEffect, useState } from 'react'
import { IoMdListBox } from 'react-icons/io'

import {
  formatDateTime,
  formatDateWithPeriod,
  renderLeaveName,
} from '@lib/helper'
import { SortT, TeamLeaveResponse, TeamLeaveParam, ColumnT, Option, LeaveT } from '@types'

import { formatTeamOptions } from '.'
import { SearchFilter } from '../components/SearchFilter'
import { LeaveApprovalModal, LEAVE_APPROVAL_MODAL } from '../../leaves/components/LeaveApprovalModal'
import { teamLeavesServices } from '../services'
import { Pagination, ExportIcon, Status, Table } from '@components'

export const ActivityPage = () => {
  const { getTeamLeaves, getTeams } = teamLeavesServices()
  const [search, setSearch] = useState<TeamLeaveParam>()
  const [leaves, setLeaves] = useState<TeamLeaveResponse>()
  const [selected, setSelected] = useState<LeaveT>()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>()

  const onChange = (value: SortT) => {
    setSearch({ ...search, sort_by: value, page: 1 })
  }

  const getTeamLeavesApprovalApi = async () => {
    const response = await getTeamLeaves(search)
    setLeaves(response)
  }

  const columns: ColumnT[] = [
    {
      label: 'Create Date',
      key: 'created_at',
      sort: true,
      width: 'md:w-[20%] 2xl:w-[10%]',
      render: (row) => formatDateTime(row.created_at) ?? '-',
    },
    {
      label: 'Submitted Employee',
      key: 'employee_name',
      width: 'md:w-[20%] 2xl:w-[10%]',
    },
    {
      label: 'Team',
      key: 'department_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[10%]',
    },
    {
      label: 'Leave Type',
      key: 'leave_type_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[10%]',
      render: (row) => renderLeaveName(row.leave_type_name),
    },
    {
      label: 'Leave Date',
      key: 'datetime_range',
      width: 'sm:min-w-[130px] md:w-[15%] 2xl:w-[20%]',
      render: (row) => formatDateWithPeriod(row.datetime_range, row.period),
    },
    {
      label: 'Status',
      key: 'status',
      width: 'w-[5%]',
      render: (row) => <Status status={row.status} type="leave" />,
    },

    {
      label: 'Approved By',
      key: 'reviewed_by_name',
      width: 'sm:min-w-[120px] md:w-[10%]',
    },
    {
      label: '',
      key: 'id',
      width: 'w-[5%]',
      render: (row) => (
        <div className="flex gap-2 justify-end">
          <label htmlFor={LEAVE_APPROVAL_MODAL}>
            <IoMdListBox
              className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[18px]"
              onClick={() => setSelected(row)}
            />
          </label>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getTeamLeavesApprovalApi()
  }, [search])

  const getTeamsOptions = async () => {
    const res = await getTeams()
    const format = formatTeamOptions(res.departments)
    setTeamOptions(format as OptionT[])
  }

  useEffect(() => {
    getTeamsOptions()
  }, [])

  const extendSearch = {...search, page: 1, per: -1, current_page: 'activity'}
  const filteredSearch = Object.fromEntries(
    Object.entries(extendSearch).filter(([_, value]) => value !== undefined)
  );
  const queryString = new URLSearchParams(filteredSearch as any).toString();

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-[2fr_1fr] items-center">
        <SearchFilter
          onChange={(filter) => setSearch({ ...filter, page: 1 })}
          search={search}
          totals={leaves?.meta.total_count ?? 0}
          teamOptions={teamOptions ?? []}
        />
        <div className="flex sm:flex-col md:flex-row gap-[8px] justify-end">
          <a
            href={`/team_leaves/export.csv?${queryString}`}
            className="btn btn-primary-outline btn-sm sm:mb-4 md:mb-0"
          >
            <ExportIcon className="fill-icon-primary ml-2" />
            &nbsp;Export
          </a>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          data={leaves?.leaves ?? []}
          sortBy={search?.sort_by ?? 'desc'}
          onSortChanged={onChange}
        />
      </div>
      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={leaves?.meta.total_pages ?? 1}
          per_page={leaves?.meta.per}
          page={leaves?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>
      {selected && (
        <LeaveApprovalModal
          data={selected.id}
        />
      )}
    </>
  )
}
