import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import {
  LeaveT,
  CreateLeaveParam,
  QuotaT,
  LeaveParam,
  MetaT,
  LeaveType,
  LeavePeriod,
} from '@types'

import {
  LeaveDeleteModal,
  LEAVE_DELETE_MODAL,
} from '../components/LeaveDeleteModal'
import { LeaveFormModal } from '../components/LeaveFormModal'
import { LeaveApprovalModal, LEAVE_APPROVAL_MODAL } from '../components/LeaveApprovalModal'
import { SearchFilter } from '../components/SearchFilter'
import { leavesServices } from '../services'
import { LeaveTable } from '../components/LeaveTable'
import { closeModal, Pagination } from '@components'
import { withApp } from '@features/layout'
import { useAuth } from '@features/layout/useAuth'

type Props = {
  leaves: LeaveT[]
  quota: QuotaT
}

export const LEAVE_CREATE_MODAL = 'leave-form-modal'
export const LEAVE_EDIT_MODAL = 'leave-edit-modal'

const LeavesPage = ({ leaves: defaultLeaves, quota: defaultQuota }: Props) => {
  const { me } = useAuth()
  const [leaves, setLeaves] = useState<LeaveT[]>(defaultLeaves)
  const [meta, setMeta] = useState<MetaT>()
  const [quota, setQuota] = useState<QuotaT>()
  const [selectedLeave, setSelectedLeave] = useState<LeaveT>()
  const [modalOpen, setModalOpen] = useState<string>()
  const [searchFilter, setSearchFilter] = useState<LeaveParam>()

  const formatNumber = (use: string = '0', quota: string = '0') => {
    return (
      parseFloat((Number(quota) - Number(use)).toFixed(2)) +
      '/' +
      parseFloat(quota)
    )
  }

  const leaveQuota = [
    {
      label: 'Vacation Leave',
      value: formatNumber(quota?.vacation.used, quota?.vacation.quota),
      img: 'vacation_leave',
    },

    {
      label: 'Sick Leave',
      value: formatNumber(quota?.sick.used, quota?.sick.quota),
      img: 'sick_leave',
    },
    {
      label: 'Work from home',
      value: 'Unlimited',
      img: 'work_from_home',
    },
    {
      label: 'Business Leave',
      value: formatNumber(quota?.business.used, quota?.business.quota),
      img: 'business_leave',
    },
    {
      label: 'Leave without Pay',
      value: formatNumber(
        quota?.leave_without_pay.used,
        quota?.leave_without_pay.quota,
      ),
      img: 'leave_without_pay_leave',
    },
    {
      label: 'Extra Vacation',
      value: formatNumber(
        quota?.extra_vacation.used,
        quota?.extra_vacation.quota,
      ),
      img: 'extra_vacation_leave',
    },
  ]

  const handleCreate = async (payload: CreateLeaveParam) => {
    const res = await leavesServices().createLeave(payload)
    if (res) {
      await refetchLeaves()
      onCloseModal(LEAVE_CREATE_MODAL)
    }
  }

  const handleUpdate = async (payload: CreateLeaveParam) => {
    if (selectedLeave) {
      const res = await leavesServices().updateLeave({
        ...selectedLeave,
        ...payload,
        id: selectedLeave.id,
      })
      if (res) {
        await refetchLeaves()
        onCloseModal(LEAVE_CREATE_MODAL)
      }
    }
  }

  const refetchLeaves = async () => {
    const fetchedLeaves = await leavesServices().getLeaves(searchFilter)
    const { leaves, quota, meta } = fetchedLeaves
    setLeaves(leaves)
    setMeta(meta)
    setQuota(isEmpty(quota) ? undefined : quota)
  }

  const handleDelete = async (id: number) => {
    await leavesServices().deleteLeave(id)
    await refetchLeaves()
    closeModal([LEAVE_DELETE_MODAL])
  }

  const onCloseModal = (name: string) => {
    setModalOpen(undefined)
    closeModal([name])
  }

  useEffect(() => {
    refetchLeaves()
  }, [searchFilter])

  const quotaComponent = leaveQuota.map((item, index) => (
    <div
      className="grid grid-cols-[1fr_2fr] w-full h-[108px] bg-white rounded-xl shadow-[0px_0px_5px_rgba(0,0,0,0.05),0px_1px_2px_rgba(0,0,0,0.15)]"
      key={index}
    >
      <div className="m-auto">
        <img src={`/assets/${item.img}.svg`} />
      </div>
      <div className="my-auto">
        <p className="text-text-default pr-[5.87px]">{item.label}</p>
        <h1 className="text-[28px] font-bold">{item.value}</h1>
      </div>
    </div>
  ))

  return (
    <div className="w-full bg-white flex flex-col">
      <div className="grid md:grid-cols-1 lg:grid-cols-[3fr_1fr] pb-[24px] items-end">
        <div>
          <h1 className="font-bold">Welcome {me?.name.split(' ')[0]}!</h1>
          <div className="font-light">Have a nice day</div>
        </div>
        <div className="grid md:justify-start lg:justify-end sm:mt-[8px]">
          <label
            className="btn btn-primary-solid btn-md sm:w-full md:w-[170px]"
            onClick={() => {
              setModalOpen(LEAVE_CREATE_MODAL)
              setSelectedLeave(undefined)
            }}
            htmlFor={LEAVE_CREATE_MODAL}
          >
            + &nbsp; Create Request
          </label>
        </div>
      </div>
      <div
        className="card grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
        justify-items-center rounded-2xl bg-background-default p-4 gap-4 mb-4"
      >
        {quotaComponent}
      </div>
      <div>
        <h2 className="font-semibold text-text-default">
          Request History
        </h2>
        <SearchFilter
          data={leaves}
          onChange={(filter) => setSearchFilter({ ...filter, page: 1 })}
          searchFilter={searchFilter}
        />

        <LeaveTable
          data={leaves}
          sort={meta?.sort_by}
          onSortChange={(data) => {
            setSearchFilter({ ...searchFilter, sort_by: data, page: 1 })
          }}
          onSelectData={(data: LeaveT, modal?: string) => {
            setSelectedLeave(data)
            setModalOpen(modal)
          }}
        />

        <div className="grid justify-end pt-4">
          <Pagination
            page={meta?.page}
            total_pages={meta?.total_pages}
            onPageChange={(page) =>
              setSearchFilter({ ...searchFilter, page: page })
            }
            onPerPageChange={(value) =>
              setSearchFilter({ ...searchFilter, per: value, page: 1 })
            }
          />
        </div>
        {modalOpen === LEAVE_EDIT_MODAL && (
          <LeaveFormModal
            onSubmit={handleUpdate}
            modalName={LEAVE_EDIT_MODAL}
            onClose={onCloseModal}
            value={
              selectedLeave
                ? {
                    period: selectedLeave?.period as LeavePeriod,
                    starts_date: selectedLeave?.starts_date,
                    ends_date: selectedLeave?.ends_date,
                    leave_type: selectedLeave?.leave_type_name as LeaveType,
                    reason: selectedLeave?.reason,
                    duration: selectedLeave?.duration,
                  }
                : undefined
            }
          />
        )}
        {modalOpen === LEAVE_CREATE_MODAL && (
          <LeaveFormModal
            onSubmit={handleCreate}
            modalName={LEAVE_CREATE_MODAL}
            onClose={onCloseModal}
          />
        )}
        {selectedLeave && (
          <LeaveDeleteModal onSubmit={handleDelete} data={selectedLeave} />
        )}
        {modalOpen === LEAVE_APPROVAL_MODAL && selectedLeave && (
          <LeaveApprovalModal data={selectedLeave.id} />
        )}
      </div>
    </div>
  )
}

export default withApp(LeavesPage)
