import React, { useEffect, useState } from 'react'

import { closeModal, Modal, Status, Table } from '@components'
import { ColumnT, ApprovalT } from '@types'
import { leavesServices } from '../services'


export const LEAVE_APPROVAL_MODAL = 'leave-approval-modal'

type Props = {
  data: number
}

export const LeaveApprovalModal = ({ data }: Props) => {
  const [approvals, setApprovals] = useState<ApprovalT[]>()

  const fetchedApprovers = async (id: number) => {
    const approvals = await leavesServices().getApprovalDetail(id)
    setApprovals(approvals)
  }

  useEffect(() => {
    fetchedApprovers(data)
  }, [data])

  const columns: ColumnT[] = [
    {
      label: 'Status',
      key: 'status',
      width: 'sm:min-w-[80px]',
      render: (row) => <Status status={row.status} type="leave" />,
    },
    {
      label: 'Approver name',
      key: 'manager_name',
      width: 'sm:min-w-[80px]',
    },
    {
      label: 'Approver role',
      key: 'role',
      width: 'sm:min-w-[80px]',
    },
    {
      label: 'Reviewed at',
      key: 'reviewed_at',
      width: 'sm:min-w-[80px]',
    },
    {
      label: 'Rejection reason',
      key: 'rejection_reason',
      width: 'sm:min-w-[80px]',
    },
  ]

  return (
    <Modal name={LEAVE_APPROVAL_MODAL} title='Approval Detail' closeIcon={true} width={500}>
      {approvals && (
        <div className="overflow-x-auto">
          <Table
            columns={columns}
            data={approvals}
          />
        </div>
      )}
      <div className="flex flex-row gap-4 justify-center pt-6">
        <button
          className="btn bg-action-primary-default border-none hover:bg-action-primary-hovered"
          onClick={() => closeModal([LEAVE_APPROVAL_MODAL])}
        >
          Done
        </button>
      </div>
    </Modal>
  )
}
