import React, { useCallback, useMemo } from 'react'
import queryString from 'query-string'
import { concat } from 'lodash'

import { teamLeavesPath } from '@path'

import { ActivityPage } from './ActivityPage'
import { HistoryPage } from './HistoryPage'
import { PendingPage } from './PendingPage'
import { ExportIcon, Tabs } from '@components'
import { withApp } from '@features/layout'
import { TeamDataT } from '@types'

export const formatTeamOptions = (data?: TeamDataT[]) => {
  return concat(
    [
      {
        label: 'All Teams',
        value: undefined,
      },
    ],

    data?.map((item) => {
      return { label: item.name, value: item.id }
    }) ?? [],
  )
}

const TeamLeaves = () => {
  const Tab = [
    {
      title: 'Pending',
      key: 'pending',
      href: teamLeavesPath,
      query: { page: 'pending' },
    },
    {
      title: 'Approval History',
      key: 'history',
      href: teamLeavesPath,
      query: { page: 'history' },
    },
    {
      title: 'Team Activities',
      key: 'activity',
      href: teamLeavesPath,
      query: { page: 'activity' },
    },
  ]

  const query = useMemo(() => {
    let queryParam = queryString.parse(window.location.search)
    if (!queryParam.page) {
      window.location.replace(`${window.location.href}?page=pending`)
    }
    return queryParam.page
  }, [window.location.search])

  const renderContent = useCallback(() => {
    switch (query) {
      case 'pending':
        return <PendingPage />
      case 'history':
        return <HistoryPage />
      case 'activity':
        return <ActivityPage />
      default:
        return
    }
  }, [query])

  return (
    <div className="w-full md:h-screen bg-white flex flex-col">
      <div className="flex justify-between sm:flex-col lg:flex-row sm:pb-[16px] md:pb-[24px] bg-red">
        <h1 className="font-bold">Team Leaves</h1>
        {/* <button className="btn btn-primary-outline btn-md w-[118px]">
          <ExportIcon className="fill-icon-primary ml-2" />
          &nbsp;Export
        </button> */}
      </div>

      <Tabs tabs={Tab} currentTab={(query as string) ?? 'pending'} />
      {renderContent()}
    </div>
  )
}
export default withApp(TeamLeaves)
