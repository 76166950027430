import React from 'react'

import { LEAVE_STATUS_OPTIONS, LEAVE_TYPE_OPTIONS } from '@lib/constants'
import { formatDateTime, FULL_DATE_PARAM } from '@lib/helper'
import { OptionT, LeaveStatus, TeamLeaveParam } from '@types'

import { Calendar, Select, Input } from '@components'
type Props = {
  onChange: (filter: TeamLeaveParam) => void
  totals: number
  search?: TeamLeaveParam
  isDisplaystatus?: boolean
  teamOptions?: OptionT[]
}

export const SearchFilter = ({
  onChange,
  totals,
  search,
  isDisplaystatus = true,
  teamOptions,
}: Props) => {
  return (
    <div className="flex flex-wrap sm:w-full md:w-auto sm:flex-col md:flex-row gap-2 my-4 md:items-center sm:items-baseline">
      {isDisplaystatus && (
        <Input
          name="query"
          value={search?.search}
          placeholder={'Search'}
          onChange={(value) =>
            onChange({ ...search, search: value as string})
          }
        />
      )}
      {isDisplaystatus && (
        <Select
          onChange={(value) => {
            onChange({ ...search, department_id: value?.value as number })
          }}
          options={teamOptions ?? []}
          value={search?.department_id}
        />
      )}
      {isDisplaystatus && (
        <Select
          onChange={(value) =>
            onChange({
              ...search,
              status:
                value?.value === '' ? undefined : (value?.value as LeaveStatus),
            })
          }
          options={LEAVE_STATUS_OPTIONS}
          value={search?.status}
        />
      )}
      <Select
        onChange={(value) =>
          onChange({ ...search, leave_type: value?.value as string })
        }
        options={LEAVE_TYPE_OPTIONS}
        value={search?.leave_type}
      />
      <Calendar
        placeholder="All Date Time Range"
        onChange={(value) => {
          onChange({
            ...search,
            starts_date: formatDateTime(
              value?.[0] as unknown as string,
              FULL_DATE_PARAM,
            ),
            ends_date: formatDateTime(
              value?.[1] as unknown as string,
              FULL_DATE_PARAM,
            ),
          })
        }}
      />
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{totals} items</span>
      </div>
    </div>
  )
}
