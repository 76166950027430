import React, { useCallback, useEffect, useMemo } from 'react'
import queryString from 'query-string'
import { concat } from 'lodash'

import { withApp } from '@features/layout'
import { userManagementPath } from '@path'

import { Tabs } from '@components'
import { UserPage } from './UsersPage'
import { TeamPage } from './TeamPage'
import { TeamDataT } from '@types'

export const formatTeamOptions = (data?: TeamDataT[]) => {
  return concat(
    [
      {
        label: 'All Teams',
        value: undefined,
      },
    ],

    data?.map((item) => {
      return { label: item.name, value: item.id }
    }) ?? [],
  )
}

const UserManagementPage = () => {
  const Tab = [
    {
      title: 'Users',
      key: 'users',
      href: userManagementPath,
      query: { page: 'users' },
    },
    {
      title: 'Teams',
      key: 'teams',
      href: userManagementPath,
      query: { page: 'teams' },
    },
  ]

  const query = useMemo(() => {
    let queryParam = queryString.parse(window.location.search)
    if (!queryParam.page) {
      window.location.replace(`${window.location.href}?page=users`)
    }
    return queryParam.page
  }, [window.location.search])

  const renderContent = useCallback(() => {
    switch (query) {
      case 'teams':
        return <TeamPage />
      case 'users':
        return <UserPage />
      default:
        return
    }
  }, [query])

  useEffect(() => {
    const query = queryString.parse(window.location.search)
    if (!query.page) {
      window.location.replace(`${window.location.href}?page=users`)
    }
  }, [])

  return (
    <div className="w-full md:h-screen bg-white flex flex-col">
      <h1 className="font-bold">User Management</h1>
      <Tabs tabs={Tab} currentTab={query as string} />
      {renderContent()}
    </div>
  )
}
export default withApp(UserManagementPage)
