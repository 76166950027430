import React, { ReactNode, useEffect } from 'react'

type Props = {
  name: string
  title?: string
  closeIcon?: boolean
  children?: ReactNode
  width?: number
  onClose?: () => void
}

export const closeModal = (names: string[]) => {
  names.forEach((name) => {
    const element = document.getElementById(name) as HTMLInputElement
    element.checked = false
  })
}

export const Modal = ({
  name,
  title,
  children,
  closeIcon = true,
  width = 600,
  onClose,
}: Props) => {
  return (
    <>
      <input type="checkbox" id={name} className="modal-toggle" />
      <div className="modal">
        <div
          className={`modal-box sm:min-w-[${width}px] md:min-w-fit p-8 overflow-y-scroll overflow-x-scroll`}
        >
          {closeIcon && (
            <label
              onClick={() => {
                onClose?.()
                closeModal([name])
              }}
              className="btn btn-sm btn-circle bg-white border-none hover:bg-surface-default-subdued absolute right-8 top-8"
            >
              <img src={'/images/close.svg'} />
            </label>
          )}
          {title && (
            <h1 className="text-2xl font-semibold mt-auto h-10">{title}</h1>
          )}
          {children}
        </div>
      </div>
    </>
  )
}
