import { AxiosError } from 'axios'
import { concat } from 'lodash'

import { axios, getErrorMessage } from '@lib/helper'
import { adminEmployeesPathApi, adminManagerPathApi, teamsPathApi } from '@path'
import {
  CreateEmployeeParam,
  CreateTeamParam,
  EmployeeDataT,
  EmployeeParam,
  EmployeeResponse,
  OptionT,
  TeamParam,
  TeamResponse,
} from '@types'

import { showToast } from '@components'

export const userManagementServices = () => {
  return {
    getTeams: async (payload?: TeamParam): Promise<TeamResponse> => {
      const res = await axios.get(teamsPathApi, { params: payload })
      return res.data || {}
    },
    getEmployees: async (
      payload?: EmployeeParam,
    ): Promise<EmployeeResponse> => {
      const res = await axios.get(adminEmployeesPathApi, { params: payload })
      return res.data || {}
    },
    createEmployee: async (payload?: CreateEmployeeParam) => {
      try {
        await axios.post(adminEmployeesPathApi, payload)
        showToast({ type: 'success', message: 'Create User Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    updateEmployee: async (payload: CreateEmployeeParam & { id: number }) => {
      try {
        await axios.put(`${adminEmployeesPathApi}/${payload.id}`, payload)
        showToast({ type: 'success', message: 'Update User Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    deleteEmployee: async (id: number) => {
      try {
        await axios.delete(`${adminEmployeesPathApi}/${id}`, null)
        showToast({ type: 'success', message: 'Delete User Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    getDirectManager: async (payload: {
      department_id: number
    }): Promise<OptionT[]> => {
      const res = await axios.get(adminManagerPathApi, { params: payload })
      const format = res?.data.map((item: EmployeeDataT) => {
        return { label: item.name, value: item.id }
      })

      return format || []
    },
    getAdminManager: async (
      payload?: EmployeeParam,
    ): Promise<OptionT[]> => {
      const res = await axios.get(adminEmployeesPathApi, { params: payload })
      const format = res?.data.employees.map((item: EmployeeDataT) => {
        return { label: item.name, value: item.id }
      })

      return format || []
    },
    createTeam: async (payload: CreateTeamParam) => {
      try {
        await axios.post(teamsPathApi, payload)
        showToast({ type: 'success', message: 'Create Team Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    updateTeam: async (payload: CreateTeamParam & { id: number }) => {
      try {
        await axios.put(`${teamsPathApi}/${payload.id}`, payload)
        showToast({ type: 'success', message: 'Update Team Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    deleteTeam: async (id: number) => {
      try {
        await axios.delete(`${teamsPathApi}/${id}`, null)
        showToast({ type: 'success', message: 'Delete Team Successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
  }
}
