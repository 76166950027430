import { axios } from '@lib/helper'

import {
  teamLeaveApprovalPathApi,
  teamLeavePathApi,
  teamLeavePendingPathApi,
  teamsPathApi,
} from '@path'
import {
  TeamLeaveApprovalResponse,
  TeamLeaveParam,
  TeamLeavePendingResponse,
  TeamLeaveResponse,
  TeamParam,
  TeamResponse,
} from '@types'

export const teamLeavesServices = () => {
  return {
    getTeamLeavesPending: async (
      payload?: TeamLeaveParam,
    ): Promise<TeamLeavePendingResponse> => {
      const res = await axios.get(teamLeavePendingPathApi, { params: payload })
      return res.data || {}
    },
    getTeamLeavesApproval: async (
      payload?: TeamLeaveParam,
    ): Promise<TeamLeaveApprovalResponse> => {
      const res = await axios.get(teamLeaveApprovalPathApi, { params: payload })
      return res.data || {}
    },
    getTeamLeaves: async (
      payload?: TeamLeaveParam,
    ): Promise<TeamLeaveResponse> => {
      const res = await axios.get(teamLeavePathApi, { params: payload })
      return res.data || {}
    },
    getTeams: async (payload?: TeamParam): Promise<TeamResponse> => {
      const res = await axios.get(teamsPathApi, { params: payload })
      return res.data || {}
    },
  }
}
